<template>
  <div id="PrintPdf">
    <h1>Formulir Pendaftaran Jama'ah UMROH</h1>
  </div>
</template>

<script>
import Order from "@/models/Order"

const OrderModel = new Order()

export default {
  data: () => ({
    isLoading: true,
    orderDetail: null
  }),
  methods: {
    getDetailOrder: function() {
      this.isLoading = true
      OrderModel.find(this.$route.params.id).then(resp => {
        
      })
    }
  }
}
</script>