import axios from "axios";
import BaseModel from "@/models/BaseModel.js";

export default class Order extends BaseModel {
  fields = {
    order_no: {
      type: "text",
      label: "Order",
      rules: "required",
    },
    nomor_va: {
      type: "enum",
    },
    status: {
      type: "text",
      label: "Status",
      rules: "required",
    },
    registrant: {
      type: "text",
      label: "Pendaftar",
      rules: "required",
    },
    sub_total: {
      type: "text",
      label: "Sub Total",
      rules: "required",
    },
    total: {
      type: "text",
      label: "Total",
      rules: "required",
    },
    total_paid: {
      type: "text",
      label: "Telah di bayar",
    },
    buyer_note: {
      type: "textarea",
      label: "Catatan Pembeli",
    },
  };
  endpoint = this.baseUrl + "orders";
  endpointPaymentHistory = this.baseUrl + "payment-history?order_id=";
  endpointUploadDokumen = this.baseUrl + "orders/documents?order_id=";
  endpointDashboard = this.baseUrl + "dashboard/order/count";
  endpointTransaksi =
    this.baseUrl + "payment-history?need_order=1&package_type=ibuluber";
  endpointCheckout = process.env.VUE_APP_API_URL + "checkout";
  endpointPaymentHistoryPost = this.baseUrl + "payment-history";
  endpointPaymentHistoryDelete = this.baseUrl + "payment-history/";
 
  endpointStepOrder = this.endpoint + "/progress";

  getPaymentHistory(id = null) {
    const bindUrl = id
      ? this.endpointPaymentHistory + id
      : this.endpointPaymentHistory;
      // console.log(this.endpointPaymentHistory)
    return new Promise((resolve, reject) => {
      axios
        .get(bindUrl)
        .then((resp) => {
          resolve(resp.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  checkoutPost(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(this.endpointCheckout, data)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  }

  postDocument(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(this.endpoint + "/documents", data)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  }

  getDashboardDt(date) {
    return new Promise((resolve, reject) => {
      axios
        .get(this.endpointDashboard + "/" + date)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  }

  getUploadDokumen(id) {
    let bindsUrl = this.endpointUploadDokumen + id;
    return new Promise((resolve, reject) => {
      axios
        .get(bindsUrl)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  assignToNextStep(nextStep, id) {
    return new Promise((resolve, reject) => {
      axios
        .patch(this.endpointStepOrder + '/' + id, { assign_progress: nextStep })
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  }

  getListTransaksi(params = {}) {
    return new Promise((resolve, reject) => {
      const url = new URL(this.endpointTransaksi);
      const urlParams = new URLSearchParams(url.search);
      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] === "string" ||
          typeof params[key] === "number"
        ) {
          urlParams.append(key, params[key]);
        }
      });
      axios
        .get(this.endpointTransaksi + "?" + urlParams.toString())
        .then((response) => {
          resolve(
            typeof params.only_count !== "undefined"
              ? response.data
              : {
                  data: response.data.data,
                  current_page: response.data.data,
                  total: response.data.total,
                }
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  printPaymentInvoice(order_no) {
    window.open(process.env.VUE_APP_API_BASE_URL + 'export/payment-proof/' + order_no)
  }

  printMutsi(id) {
    window.open(process.env.VUE_APP_API_BASE_URL + 'export/mutasi/' + id)
  }

  printDocShispent(id) {
    window.open(process.env.VUE_APP_API_BASE_URL + 'export/document-shispent/' + id )
  }

  addPaymentHistory(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(this.endpointPaymentHistoryPost, data)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  }

  deletePaymentHistory(id){
    return new Promise((resolve, reject) => {
      axios
        .delete(this.endpointPaymentHistoryDelete + id)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err))
    })
  }

  orderUpdate(data, id) {
    data.append("_method", "PATCH");
    return new Promise((resolve, reject) => {
      axios
        .post(this.endpoint + "/" + id, data)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  }

  resendPilgrimPdfSms(pilgrimId) {
    let data = new FormData();
    data.append("pilgrim_id", pilgrimId)
    data.append("_method", "POST");
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + "pilgrim/send-proof-pilgrim-pdf", data)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  } 

  resendKwitansiSms(pilgrimId, paymentHistoryId) {
    let data = new FormData();
    data.append("pilgrim_id", pilgrimId)
    data.append("payment_history_id", paymentHistoryId)
    data.append("_method", "POST");

    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + "pilgrim/send-kwitansi-pdf", data)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  } 

  resendVirtualAccount(orderId) {
    let data = new FormData()
    data.append('order_id', orderId)
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + "pilgrim/resend-va", data)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    })
  }

  doExportExcel(params) {
    let paramBuild = ''
    for (const key in params) {
      paramBuild = paramBuild + key + '=' + params[key]
    }
    window.open(this.webUrl + 'export/order-document/' + window.localStorage.getItem('access_token') + '?' + paramBuild);
  }
}
